<template>
  <div class="dasboard">
    <div class="page-header">
      <h3 class="page-title">Game Owner Statistic</h3>
      <select
        style="width: 250px"
        onchange="window.location.reload()"
        class="form-control"
      >
        <option>This Month</option>
        <option>All Time</option>
      </select>
    </div>

    <div class="row" v-if="role === 'superadmin'">
      <div class="col-xl-6 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="/owner">{{ companyCount || "0" }}</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-info">
                  <span class="mdi mdi-domain icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">Company</h6>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="/billing">0</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-warning">
                  <span class="mdi mdi-basket-outline icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">Transaksi</h6>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="/owner">{{ userCount.data || "0" }}</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-danger">
                  <span class="mdi mdi-account icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">User</h6>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="#">{{ ruleCount.data || "0" }}</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-success">
                  <span class="mdi mdi-book icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">Rules</h6>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="#">{{
                      submissionRuleCount.data || "0"
                    }}</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-primary">
                  <span class="mdi mdi-book-multiple icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">Submission Rules</h6>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-xl-4 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="/maker">{{ makerCount.data || "0" }}</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-danger">
                  <span class="mdi mdi-account icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">Maker</h6>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="/player">{{ playerCount.data || "0" }}</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-info">
                  <span class="mdi mdi-account icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">Player</h6>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="d-flex align-items-center align-self-start">
                  <h3 class="mb-0">
                    <router-link to="/rules/rewards">{{
                      ruleCount.data || "0"
                    }}</router-link>
                  </h3>
                </div>
              </div>
              <div class="col-3">
                <div class="icon icon-box-success">
                  <span class="mdi mdi-book icon-item"></span>
                </div>
              </div>
            </div>
            <h6 class="text-muted font-weight-normal">Rules</h6>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="page-header">
      <h3 class="page-title">Monthly Subscription Statistic</h3>
      <label style="float: right; color: #fff; margin-right: 15px">Latest 12 Month</label>
    </div>
    <div id="container"></div>
    <br /><br />

    <div class="row">
      <div class="page-header">
        <h3 class="page-title">Game Owner Billings</h3>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td>Pay Date</td>
                    <td>Billing Period</td>
                    <td>Total Amount</td>
                    <td>Download Invoice</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in biling" :key="index">
                    <td>
                      {{ formatDate(data.billing_transaction.created_at) }}
                    </td>
                    <td>
                      {{ formatDate(data.billing_period_start) }} -
                      {{ formatDate(data.billing_period_end) }}
                    </td>
                    <td>Rp. {{ formatPrice(data.billing_transaction.amount) }}</td>
                    <td>
                      <a
                        :href="data.billing_transaction.invoice"
                        class="btn btn-success"
                        download
                        target="_blank"
                        ><i class="mdi mdi-download m-0"></i
                      ></a>
                    </td>
                  </tr>
                  <!-- <tr v-for="(data) in paginatedTransaction" :key="data.id">
                    <td>
                        {{ data.owner.company_name || '-' }}
                    </td>
                    <td>{{ data.number_of_players || '-' }} Player</td>
                    <td>{{ data.invoice_number || '-' }}</td>
                    <td>-</td>
                    <td>{{ data.type || '-' }}</td>
                    <td>{{ formatDate(data.start_month) + ' - ' + formatDate(data.end_month) || '-' }}</td>
                    <td>
                      <div :class="['badge', data.payment_status === 'Paid' ? 'badge-outline-success' : 'badge-outline-danger']">
                        {{ data.payment_status || '-' }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="paginatedTransaction.length === 0">
                    <td colspan="7" class="text-center">No data available</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import {
  countMaker,
  countPlayer,
  countRule,
  countUser,
  countCompany,
  countSubmissionRule,
} from "../services/dashboardService";
import axios from "axios";
import config from "../config/config";
import store from "../store";
import moment from "moment";
export default {
  name: "DashboardView",
  data() {
    return {
      makerCount: 0,
      biling: [],
      playerCount: 0,
      ruleCount: 0,
      submissionRuleCount: 0,
      userCount: 0,
      companyCount: 0,
      role: JSON.parse(JSON.parse(localStorage.getItem("user"))).data.role,
    };
  },
  mounted() {
    this.getBiling(),
      (Highcharts.theme = {
        colors: [
          "#058DC7",
          "#50B432",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        chart: {
          backgroundColor: {
            linearGradient: [25, 28, 36],
            stops: [
              [0, "rgb(25, 28, 36)"],
              [1, "rgb(25, 28, 36)"],
            ],
          },
        },
        title: {
          style: {
            color: "#fff",
            font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
          },
        },
        subtitle: {
          style: {
            color: "#fff",
            font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
          },
        },
        legend: {
          itemStyle: {
            font: "9pt Trebuchet MS, Verdana, sans-serif",
            color: "white",
          },
          itemHoverStyle: {
            color: "gray",
          },
        },
      });
    // Apply the theme
    Highcharts.setOptions(Highcharts.theme);

    Highcharts.chart("container", {
      accessibility: { enabled: false },
      title: {
        text: "",
        x: -20, //center
      },
      subtitle: {
        text: "Latest 12 Month",
        x: -20,
      },
      xAxis: {
        categories: [
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
        ],
      },
      yAxis: {
        title: {
          text: "Number of Player",
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: "#808080",
          },
        ],
      },
      tooltip: {
        valueSuffix: " players",
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        borderWidth: 0,
      },

      series: [
        {
          name: "Renewal",
          data: [206, 221, 138, 106, 122, 302, 230, 400, 340, 100, 90, 100],
        },
        {
          name: "New Registration",
          data: [255, 281, 113, 904, 156, 207, 133, 202, 241, 301, 191, 111],
        },
      ],
    });

    if (this.role === "superadmin") {
      countUser().then((data) => {
        this.userCount = data;
      });
      countCompany().then((data) => {
        this.companyCount = data;
      });
      countSubmissionRule().then((data) => {
        this.submissionRuleCount = data;
      });
    } else {
      countMaker().then((data) => {
        this.makerCount = data;
      });
      countPlayer().then((data) => {
        this.playerCount = data;
      });
    }
    countRule().then((data) => {
      this.ruleCount = data;
    });
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    getBiling() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}billing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.biling = resp.data.data;
          // console.log(_.biling);
          // if (resp.data.data == "") {
          //   this.$router.push({ name: "AddQuota" });
          // } else if (resp.data.data[0].status == "pending") {
          //   this.$router.push({ name: "AddQuota" });
          // }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>
