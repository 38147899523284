<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Game maker</h3>
    </div>
    <div class="col-12 grid-margin">
      <!-- <button
        type="button"
        id="searchcolumn-btn"
        class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
      </button>
      &nbsp; -->
      <!-- <router-link
        :to="{ name: 'CreateMaker' }"
        class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-plus btn-icon-prepend"></i> Insert new Data
      </router-link> -->
    </div>
    <!-- <div
      class="col-lg-6 col-md-12 grid-margin"
      style="display: none"
      id="searchcolumn"
    >
      <div class="card">
        <div class="card-body">
          <div class="btn-top-right">
            <button
              id="searchcolumn-close"
              class="btn btn-dark btn-xs"
              type="button"
            >
              <i class="mdi mdi-window-close btn-icon-prepend"></i>
            </button>
          </div>
          <form class="forms-sample">
            <div class="form-group">
              <label for="exampleInputUsername1">Company </label>
              <select
                class="js-example-basic-single form-control"
                style="width: 100%"
                data-select2-id="1"
                tabindex="-1"
                aria-hidden="true"
              >
                <option v-for="option in companyOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>Name</label>
                <input class="form-control" type="text" placeholder="All" />
              </div>
              <div class="col">
                <label>Email</label>
                <input class="form-control" type="text" placeholder="All" />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Sort By:</label>
                <select class="form-control">
                  <option>Join Date</option>
                  <option>Name</option>
                  <option>Company Name</option>
                </select>
              </div>
              <div class="col">
                <label>Ordering</label>
                <select class="form-control">
                  <option>Descending</option>
                  <option>Ascending</option>
                </select>
              </div>
            </div>
            <div class="form-group text-right">
              <button
                type="button"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
              >
                <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div> -->

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="input-group">
              <input
                v-model.trim="search"
                @keyup="getBilingAdminsearch"
                placeholder="Search"
                style="
                  float: right;
                  background-color: transparent;
                  border-color: aliceblue;
                  color: aliceblue;
                "
                class="col-md-2"
              />
              <div class="input-group-append">
                <span class="input-group-text" style="border-color: aliceblue"
                  ><i class="mdi mdi-magnify"></i
                ></span>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Company</th>
                  <th>Join Date</th>
                  <th>Squad</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedMaker" :key="data.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    {{ data.name || "-" }}
                  </td>
                  <td>{{ data.email || "-" }}</td>
                  <td>
                    <span v-if="data.company_name == null">-</span>
                    <span v-if="data.company_name"> {{ data.company_name }}</span>
                  </td>

                  <td>{{ formatDate(data.created_at) || "-" }}</td>
                  <td>
                    <span v-if="data.division == null">-</span>
                    <span v-if="data.division">
                      {{ data.division.name || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="badge badge-success" v-if="data.status == 'Inactive'">{{
                      data.status || "-"
                    }}</span>

                    <span class="badge badge-danger" v-if="data.status != 'Inactive'">{{
                      data.status || "-"
                    }}</span>
                  </td>
                </tr>
                <tr v-if="paginatedMaker.length === 0">
                  <td colspan="7" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="maker.length > 0">
            <PaginationComponents
              :totalItems="maker.length"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @page-changed="onPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getCompany } from "../../services/makerService";
import { getMakerAdmin } from "../../services/makerService";
import { addToPlayer } from "../../services/makerService";
import moment from "moment";
import PaginationComponents from "../../components/PaginationComponents.vue";
import swal from "sweetalert2";
import axios from "axios";
import config from "../../config/config";
import store from "../../store";
export default {
  components: {
    PaginationComponents,
  },
  data() {
    return {
      search: "",
      companyOptions: [],
      maker: [],
      currentPage: 1,
      itemsPerPage: 10,
      mountedDone: false,
    };
  },
  computed: {
    filteredItems() {
      return this.maker.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    paginatedMaker() {
      if (this.mountedDone) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.maker.length > 0 ? this.maker.slice(start, end) : [];
      } else {
        return [];
      }
    },
  },
  methods: {
    getBilingAdminsearch() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}admin/user/maker`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (this.search) {
            this.maker = resp.data.data.filter((maker) =>
              maker.name.toLowerCase().includes(this.search.toLowerCase())
            );
          } else {
            _.maker = resp.data.data;
          }
        });
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    async fetchData() {
      await getCompany().then((response) => {
        response.forEach((item) => {
          this.companyOptions.push({
            value: item.id,
            label: item.company_name,
          });
        });
      });

      await getMakerAdmin()
        .then((response) => {
          this.maker = response.data;
        })
        .catch((error) => {
          console.error("Error fetching makers:", error);
        });
    },
    onPageChanged(page) {
      this.currentPage = page;
    },
    async addToPlayer(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to add this to player?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await addToPlayer(id)
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                console.error("Error adding to player:", error);
              });
          }
        });
    },
  },
  async mounted() {
    await this.fetchData().then(() => {
      this.mountedDone = true;
    });
    $("#searchcolumn-btn").click(() => {
      $("#searchcolumn").show(300);
      $("#searchcolumn-btn").hide(300);
    });

    $("#searchcolumn-close").click(() => {
      $("#searchcolumn").hide(300);
      $("#searchcolumn-btn").show(300);
    });
  },
};
</script>
