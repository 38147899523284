<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Game player</h3>
    </div>
    <div class="col-12 grid-margin"></div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="input-group">
              <input
                v-model.trim="search"
                @keyup="getBilingAdminsearch"
                placeholder="Search"
                style="
                  float: right;
                  background-color: transparent;
                  border-color: aliceblue;
                  color: aliceblue;
                "
                class="col-md-2"
              />
              <div class="input-group-append">
                <span class="input-group-text" style="border-color: aliceblue"
                  ><i class="mdi mdi-magnify"></i
                ></span>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Company</th>
                  <th>Join Date</th>
                  <th>Squad</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedPlayer" :key="data.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    {{ data.name || "-" }}
                  </td>
                  <td>{{ data.email || "-" }}</td>
                  <td>
                    <span v-if="data.company_name == null">-</span>
                    <span v-if="data.company_name"> {{ data.company_name }}</span>
                  </td>

                  <td>{{ formatDate(data.created_at) || "-" }}</td>
                  <td>
                    <span v-if="data.division == null">-</span>
                    <span v-if="data.division">
                      {{ data.division.name || "-" }}
                    </span>
                  </td>
                  <td>
                    <span class="badge badge-success" v-if="data.status == 'Inactive'">{{
                      data.status || "-"
                    }}</span>

                    <span class="badge badge-danger" v-if="data.status != 'Inactive'">{{
                      data.status || "-"
                    }}</span>
                  </td>
                </tr>
                <tr v-if="paginatedPlayer.length === 0">
                  <td colspan="7" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="player.length > 0">
            <PaginationComponents
              :totalItems="player.length"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @page-changed="onPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getCompanyAdmin } from "../../services/playerService";
import { getPlayerAdmin } from "../../services/playerService";
import { addToMaker } from "../../services/playerService";
import moment from "moment";
import PaginationComponents from "../../components/PaginationComponents.vue";
import swal from "sweetalert2";
import axios from "axios";
import config from "../../config/config";
import store from "../../store";

export default {
  name: "ListPlayerAdmin",
  components: {
    PaginationComponents,
  },
  data() {
    return {
      search: "",
      companyOptions: [],
      player: [],
      currentPage: 1,
      itemsPerPage: 10,
      mountedDone: false,
    };
  },
  computed: {
    filteredItems() {
      return this.player.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },

    paginatedPlayer() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.player.slice(start, end);
    },
  },
  methods: {
    getBilingAdminsearch() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}admin/user/player`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        .then((resp) => {
          if (this.search) {
            this.player = resp.data.data.filter((player) =>
              player.name.toLowerCase().includes(this.search.toLowerCase())
            );
          } else {
            _.player = resp.data.data;
          }
        });
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    async fetchData() {
      await getCompanyAdmin().then((response) => {
        response.forEach((item) => {
          this.companyOptions.push({
            value: item.id,
            label: item.company_name,
          });
        });
      });

      await getPlayerAdmin()
        .then((response) => {
          this.player = response.data;
        })
        .catch((error) => {
          console.error("Error fetching players:", error);
        });
    },
    onPageChanged(page) {
      this.currentPage = page;
    },
    async addToMaker(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to add this to maker?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await addToMaker(id)
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                console.error("Error adding to maker:", error);
              });
          }
        });
    },
  },
  async mounted() {
    this.getBilingAdminsearch();
    await this.fetchData().then(() => {
      this.mountedDone = true;
    });

    $("#searchcolumn-btn").click(() => {
      $("#searchcolumn").show(300);
      $("#searchcolumn-btn").hide(300);
    });

    $("#searchcolumn-close").click(() => {
      $("#searchcolumn").hide(300);
      $("#searchcolumn-btn").show(300);
    });
  },
};
</script>
