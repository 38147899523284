<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Create New Game Owner</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            @submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <div class="form-group row">
              <div class="col">
                <label for="company-name">Company Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="company-name"
                  v-model="ownerStore.companyName"
                />
              </div>
              <div class="col">
                <label>Owner Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="owner-name"
                  v-model="ownerStore.ownerName"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="email-address">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email-address"
                  v-model="ownerStore.emailAddress"
                />
              </div>
              <div class="col">
                <label>Phone Number</label>
                <input
                  type="tel"
                  class="form-control"
                  id="phone-number"
                  v-model="ownerStore.phoneNumber"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="set-password">Set Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="set-password"
                  v-model="ownerStore.password"
                />
              </div>
              <div class="col">
                <label>Number of Players</label>
                <input
                  type="number"
                  class="form-control"
                  id="number-of-players"
                  v-model="ownerStore.numberOfPlayers"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="subscription">Subscription</label>
                <select
                  class="form-control"
                  id="subscription"
                  v-model="ownerStore.subscription"
                >
                  <option value="1 Month">1 Month</option>
                  <option value="2 Month">2 Month</option>
                  <option value="3 Month">3 Month</option>
                  <option value="4 Month">4 Month</option>
                  <option value="5 Month">5 Month</option>
                  <option value="6 Month">6 Month</option>
                  <option value="7 Month">7 Month</option>
                  <option value="8 Month">8 Month</option>
                  <option value="9 Month">9 Month</option>
                </select>
              </div>
              <div class="col">
                <label>Payment Status</label>
                <select
                  class="form-control"
                  id="payment-status"
                  v-model="ownerStore.paymentStatus"
                >
                  <option value="paid">Paid</option>
                  <option value="unpaid">Unpaid</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <label>Company Logo</label>
                    <input
                      type="file"
                      class="form-control dropify"
                      @change="handleFileChange"
                      ref="fileInput"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6">
                                <label for="domain">Domain</label>
                                <input type="text" class="form-control" id="domain" v-model="ownerStore.domain">
                            </div> -->
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div
                  class="spinner-border text-warning me-3"
                  :style="{ width: '14px', height: '14px' }"
                  role="status"
                  v-if="loading"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "dropify";
import { storeOwner } from "@/services/ownerService";
import Swal from "sweetalert2";

export default {
  data: () => ({
    ownerStore: {
      companyName: "",
      ownerName: "",
      emailAddress: "",
      phoneNumber: "",
      password: "",
      numberOfPlayers: "",
      subscription: "",
      paymentStatus: "",
      companyLogo: null,
      domain: "",
    },
    loading: false,
  }),
  mounted() {
    $(".dropify").dropify({
      messages: {
        default: "Drag and drop a file here or click",
        replace: "Drag and drop or click to replace",
        remove: "Remove",
        error: "Ooops, something wrong happened.",
      },
      error: {
        fileSize: "The file size is too big ({{ value }} max).",
        minWidth: "The image width is too small ({{ value }}}px min).",
        maxWidth: "The image width is too big ({{ value }}}px max).",
        minHeight: "The image height is too small ({{ value }}}px min).",
        maxHeight: "The image height is too big ({{ value }}}px max).",
        imageFormat: "The image format is not allowed ({{ value }} only).",
      },
      tpl: {
        wrap: '<div class="dropify-wrapper"></div>',
        loader: '<div class="dropify-loader"></div>',
        message:
          '<div class="dropify-message"><span class="file-icon" /> <p>{{ default }}</p></div>',
        preview:
          '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"></div></div>',
        filename: '<p class="dropify-filename"><span class="file-icon" /> {{ name }}</p>',
        file: '<div class="dropify-file"></div>',
        clearButton: '<button type="button" class="dropify-clear">Remove</button>',
        errorLine: '<p class="dropify-error">{{ error }}</p>',
        errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>',
      },
    });

    this.ownerStore.subscription = "1 Month";
    this.ownerStore.paymentStatus = "paid";
  },
  methods: {
    async submitForm() {
      this.loading = true;
      try {
        await storeOwner(this.ownerStore);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Game owner created successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.push({ name: "ListOwner" });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.errors[0],
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        this.ownerStore.companyLogo = event.target.files[0];
      } else {
        this.ownerStore.companyLogo = null; // Ensure it's null if no files are selected
      }
    },
  },
};
</script>
