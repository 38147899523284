<template>
  <!-- component:component/SidebarComponents.html -->
  <div class="wrapper">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <div
        class="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top"
      >
        <router-link
          :to="{ name: 'Dashboard' }"
          class="sidebar-brand brand-logo"
          style="text-align: center"
          ><img src="../assets/images/logo-big.svg" alt="logo"
        /></router-link>
        <router-link :to="{ name: 'Dashboard' }" class="sidebar-brand brand-logo-mini"
          ><img src="../assets/images/logo-big.svg" alt="logo"
        /></router-link>
      </div>
      <ul class="nav" style="padding-top: 90px">
        <li class="nav-item menu-items" :class="{ active: $route.name === 'Dashboard' }">
          <router-link :to="{ name: 'Dashboard' }" class="nav-link">
            <span class="menu-icon">
              <i class="mdi mdi-speedometer"></i>
            </span>
            <span class="menu-title">Dashboard</span>
          </router-link>
        </li>

        <li class="nav-item nav-category" v-if="roleSuperadmin">
          <span class="nav-link">Super User Area</span>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isOwnerRoute }"
          v-if="roleSuperadmin"
        >
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            href="home.html#owner"
            aria-expanded="false"
            aria-controls="owner"
          >
            <span class="menu-icon">
              <i class="mdi mdi-account-star"></i>
            </span>
            <span class="menu-title">Game Owner</span>
            <i class="mdi mdi-chevron-down ms-auto"></i>
          </a>
          <div class="collapse" :class="{ show: isOwnerRoute }" id="owner">
            <!-- {{ biling.data }} -->
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'CreateOwner' }" class="nav-link">
                  Create Game Owner
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!-- <li class="nav-item menu-items" :class="{ 'active': isBillingRoute }">
        <router-link :to="{name:'Billing'}" class="nav-link">
          <span class="menu-icon">
            <i class="mdi mdi-cards"></i>
          </span>
          <span class="menu-title">Billing </span>
        </router-link>
      </li> -->

        <!-- <li class="nav-item menu-items" :class="{ 'active': isVoucherRoute }">
        <router-link :to="{name:'ListVoucher'}" class="nav-link">
          <span class="menu-icon">
            <i class="mdi mdi-ticket-confirmation"></i>
          </span>
          <span class="menu-title">Voucher </span>
        </router-link>
      </li> -->

        <li class="nav-item nav-category">
          <span class="nav-link">Administrator Menu</span>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isOwnerRoute }"
          v-if="roleSuperadmin"
        >
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            href="home.html#owner"
            aria-expanded="false"
            aria-controls="owner"
          >
            <span class="menu-icon">
              <i class="mdi mdi-account-star"></i>
            </span>
            <span class="menu-title">Game Owner</span>
            <i class="mdi mdi-chevron-down ms-auto"></i>
          </a>
          <div class="collapse" :class="{ show: isOwnerRoute }" id="owner">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'ListOwner' }" class="nav-link">
                  All Data
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li
          class="nav-item menu-items"
          :class="{ active: isAdminMakerRoute }"
          v-if="roleSuperadmin"
        >
          <router-link :to="{ path: '/adminmaker' }" class="nav-link">
            <span class="menu-icon">
              <i class="mdi mdi-account-multiple"></i>
            </span>
            <span class="menu-title"> Game Maker </span>
          </router-link>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isAdminPlayer }"
          v-if="roleSuperadmin"
        >
          <router-link :to="{ path: '/adminplayer' }" class="nav-link">
            <span class="menu-icon">
              <i class="mdi mdi-account-multiple"></i>
            </span>
            <span class="menu-title"> Game Player </span>
          </router-link>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isMakerRoute }"
          v-if="roleOwner"
        >
          <router-link
            :to="{ name: 'ListMaker' }"
            class="nav-link"
            v-if="biling.length > 0 && status == 'paid'"
          >
            <span class="menu-icon">
              <i class="mdi mdi-account-multiple-outline"></i>
            </span>
            <span class="menu-title">Game Maker</span>
          </router-link>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isPlayerRoute }"
          v-if="roleOwner"
        >
          <router-link
            :to="{ name: 'ListPlayer' }"
            class="nav-link"
            v-if="biling.length > 0 && status == 'paid'"
          >
            <span class="menu-icon">
              <i class="mdi mdi-account-multiple-outline"></i>
            </span>
            <span class="menu-title">Game Player</span>
          </router-link>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isPackageRoute }"
          v-if="roleSuperadmin"
        >
          <router-link :to="{ name: 'ListPackage' }" class="nav-link">
            <span class="menu-icon">
              <i class="mdi mdi-package"></i>
            </span>
            <span class="menu-title">Package </span>
          </router-link>
        </li>

        <li class="nav-item menu-items" :class="{ active: isBillingRoute }">
          <router-link :to="{ name: 'Billing' }" class="nav-link">
            <span class="menu-icon">
              <i class="mdi mdi-cards"></i>
            </span>
            <span class="menu-title">Billing </span>
          </router-link>
          <!-- <a class="nav-link" href="" data-bs-toggle="modal" data-bs-target="#dalamPengembangan">
            <span class="menu-icon">
              <i class="mdi mdi-cards"></i>
            </span>
            <span class="menu-title">Billing </span>
          </a> -->
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isQuotaRoute }"
          v-if="roleSuperadmin"
        >
          <router-link :to="{ name: 'discount' }" class="nav-link">
            <span class="menu-icon">
              <i class="mdi mdi-sale"></i>
            </span>
            <span class="menu-title">Discount </span>
          </router-link>
          <!-- <a class="nav-link" href="" data-bs-toggle="modal" data-bs-target="#dalamPengembangan">
            <span class="menu-icon">
              <i class="mdi mdi-cards"></i>
            </span>
            <span class="menu-title">Billing </span>
          </a> -->
        </li>

        <!-- <li class="nav-item menu-items" :class="{ active: isQuotaRoute }">
          <router-link :to="{ name: 'Quota' }" class="nav-link">
            <span class="menu-icon">
              <i class="mdi mdi-account-multiple-plus"></i>
            </span>
            <span class="menu-title">Quota </span>
          </router-link>
        </li> -->

        <li
          class="nav-item menu-items"
          :class="{ active: isRulesRoute }"
          v-if="biling.length > 0 && status == 'paid'"
        >
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            href="https://dashboard.gamiteam.app/home/home.html#rules"
            aria-expanded="false"
            aria-controls="rules"
            v-if="roleOwner"
          >
            <span class="menu-icon">
              <i class="mdi mdi-av-timer"></i>
            </span>
            <span class="menu-title">Rules</span>
            <i class="mdi mdi-chevron-down ms-auto"></i>
          </a>
          <div class="collapse" :class="{ show: isRulesRoute }" id="rules">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'ListRewards' }" class="nav-link">
                  Reward
                </router-link>
                <!-- <a href="" data-bs-toggle="modal" data-bs-target="#dalamPengembangan" class="nav-link">Reward</a> -->
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'ListConsequence' }" class="nav-link">
                  Consequence
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Compensation' }" class="nav-link"
                  >Compensation</router-link
                >
              </li>
            </ul>
          </div>
        </li>

        <!-- <li class="nav-item menu-items">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            href="https://dashboard.gamiteam.app/home/home.html#non_rules"
            aria-expanded="false"
            aria-controls="non_rules"
          >
            <span class="menu-icon">
              <i class="mdi mdi-backup-restore"></i>
            </span>
            <span class="menu-title">Non Rules</span>
            <i class="mdi mdi-chevron-down ms-auto"></i>
          </a>
          <div class="collapse" id="non_rules">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <a class="nav-link" href="https://dashboard.gamiteam.app/gift"
                  >Gift</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://dashboard.gamiteam.app/punishment"
                  >Punishment</a
                >
              </li>
              
            </ul>
          </div>
        </li> -->

        <li
          class="nav-item menu-items"
          :class="{ active: isSquadRoute }"
          v-if="roleOwner"
        >
          <router-link
            :to="{ name: 'ListSquad' }"
            class="nav-link"
            v-if="biling.length > 0 && status == 'paid'"
          >
            <span class="menu-icon">
              <i class="mdi mdi-account-switch"></i>
            </span>
            <span class="menu-title">Squad</span>
          </router-link>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isRedeemRoute }"
          v-if="roleOwner"
        >
          <router-link
            :to="{ name: 'ListRedeem' }"
            class="nav-link"
            v-if="biling.length > 0 && status == 'paid'"
          >
            <span class="menu-icon">
              <i class="mdi mdi-wallet-giftcard"></i>
            </span>
            <span class="menu-title">Redeem</span>
          </router-link>
        </li>

        <li
          class="nav-item menu-items"
          :class="{ active: isApprovalRoute }"
          v-if="biling.length > 0 && status == 'paid'"
        >
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            href="https://dashboard.gamiteam.app/home/home.html#approval"
            aria-expanded="false"
            aria-controls="approval"
            v-if="roleOwner"
          >
            <span class="menu-icon">
              <i class="mdi mdi-check-decagram-outline"></i>
            </span>
            <span class="menu-title">Approval</span>
            <i class="mdi mdi-chevron-down ms-auto"></i>
          </a>
          <div class="collapse" :class="{ show: isApprovalRoute }" id="approval">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link :to="{ name: 'RewardsApproval' }" class="nav-link">
                  Reward
                </router-link>
                <!-- <a href="" data-bs-toggle="modal" data-bs-target="#dalamPengembangan" class="nav-link">Reward</a> -->
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'PunishmentApproval' }" class="nav-link">
                  Punishment
                </router-link>
                <!-- <a href="" data-bs-toggle="modal" data-bs-target="#dalamPengembangan" class="nav-link">Punishment</a> -->
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'ConsequenceApproval' }" class="nav-link">
                  Consequence
                </router-link>
                <!-- <a href="" data-bs-toggle="modal" data-bs-target="#dalamPengembangan" class="nav-link">Consequence</a> -->
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'CompensationApproval' }" class="nav-link"
                  >Compensation</router-link
                >
                <!-- <a href="" data-bs-toggle="modal" data-bs-target="#dalamPengembangan" class="nav-link">Compensation</a> -->
              </li>
            </ul>
          </div>
        </li>

        <!-- <li class="nav-item menu-items">
        <a
          class="nav-link"
          data-bs-toggle="collapse"
          href="https://dashboard.gamiteam.app/home/home.html#setting"
          aria-expanded="false"
          aria-controls="setting"
        >
          <span class="menu-icon">
            <i class="mdi mdi-cog"></i>
          </span>
          <span class="menu-title">Setting</span>
          <i class="mdi mdi-chevron-down ms-auto"></i>
        </a>
        <div class="collapse" id="setting">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item">
              <a class="nav-link" href="https://dashboard.gamiteam.app/label"
                >Master Label &amp; Title</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://dashboard.gamiteam.app/redeem_category"
                >Redeem Category</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://dashboard.gamiteam.app/redeem"
                >Redeem</a
              >
            </li>
          </ul>
        </div>
      </li> -->
      </ul>
    </nav>
    <div
      class="modal fade"
      id="dalamPengembangan"
      tabindex="-1"
      aria-labelledby="dalamPengembanganLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="dalamPengembanganLabel">Dalam Pengembangan</h4>
            <button
              type="button"
              class="btn-close bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Fitur ini sedang dalam pengembangan</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../config/config";
import store from "../store";
export default {
  data() {
    return {
      biling: [],
      status: "",
      role: JSON.parse(JSON.parse(localStorage.getItem("user"))).data.role,
    };
  },
  mounted() {
    this.getBiling();
  },
  computed: {
    isOwnerRoute() {
      return this.$route.matched.some((record) => record.name === "Owner");
    },

    isMakerRoute() {
      return this.$route.matched.some((record) => record.name === "Maker");
    },

    isPlayerRoute() {
      return this.$route.matched.some((record) => record.name === "Player");
    },

    isBillingRoute() {
      return this.$route.matched.some((record) => record.name === "Billing");
    },

    isVoucherRoute() {
      return this.$route.matched.some((record) => record.name === "Voucher");
    },

    isSquadRoute() {
      return this.$route.matched.some((record) => record.name === "ListSquad");
    },

    isRedeemRoute() {
      return this.$route.matched.some((record) => record.name === "Redeem");
    },

    isRulesRoute() {
      return this.$route.matched.some((record) => record.name === "Rules");
    },

    isPaymentRoute() {
      return this.$route.matched.some((record) => record.name === "Payment");
    },

    isApprovalRoute() {
      return this.$route.matched.some((record) => record.name === "Approval");
    },

    isQuotaRoute() {
      return this.$route.matched.some((record) => record.name === "Quota");
    },

    isPackageRoute() {
      return this.$route.matched.some((record) => record.name === "Package");
    },

    isAdminMakerRoute() {
      return this.$route.matched.some((record) => record.name === "AdminMaker");
    },

    isAdminPlayer() {
      return this.$route.matched.some((record) => record.name === "AdminPlayer");
    },

    isDiscountRoute() {
      return JSON.parse(this.$store.state.user).data.role === "superadmin";
    },

    roleOwner() {
      return JSON.parse(this.$store.state.user).data.role === "owner";
    },

    roleSuperadmin() {
      return JSON.parse(this.$store.state.user).data.role === "superadmin";
    },
  },
  methods: {
    getBiling() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}billing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.biling = resp.data.data;
          // console.log(resp.data.data.length);
          _.status = resp.data.data[0].billing_transaction.status;
          console.log(resp.data.data[0].billing_transaction.status);
          // console.log(_.biling);
          // console.log("SLIDE", _.biling);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style scoped>
.modal-backdrop.fade.show {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
