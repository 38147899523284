<template>
  <div>
    <!-- <h1>{{ biling.status }}</h1> -->
  </div>
</template>
<script>
import axios from "axios";
import config from "../config/config";
import store from "../store";

export default {
  name: "DashboardView",
  data() {
    return {
      biling: [],
      pending: "pending",
      success: "success",
      role: JSON.parse(JSON.parse(localStorage.getItem("user"))).data.role,
    };
  },
  mounted() {
    this.getBiling();
  },
  methods: {
    getBiling() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}billing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.biling = resp.data.data;
          if (resp.data.data == "") {
            this.$router.push({ name: "AddQuota" });
          } else if (resp.data.data[0].status == "pending") {
            this.$router.push({ name: "AddQuota" });
          } else if (resp.data.data[0].status == "success") {
            this.$router.push({ name: "Dashboard" });
          } else if (resp.data.data != null) {
            this.$router.push({ name: "Dashboard" });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>
