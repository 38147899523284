import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL;

export const storePlayer = async (data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}user`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const getPlayer = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}user/player`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    
    return response.data;
}
export const getPlayerAdmin = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}admin/user/player`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    
    return response.data;
}

export const getDetailPlayer = async (id) => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}user/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const updatePlayer = async (id, data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}user/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const deletePlayer = async (id) => {
    const token = store.state.authToken;
    const response = await axios.delete(`${API_URL}user/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const getCompany = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}company-list`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const getCompanyAdmin = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}admin/company-list`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const getSquad = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}division`,{
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const addToMaker = async (id) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}add-to-maker/${id}`, null, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}