<template>
  <div class="row" v-if="!roleSuperadmin">
    <div class="page-header">
      <h3 class="page-title">Billing</h3>
    </div>
    <!-- <div class="col-12 grid-margin">
      <router-link :to="{ name: 'KuotaAdd' }" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2">
        <i class="mdi mdi-plus btn-icon-prepend"></i> Add Kuota
      </router-link>
    </div> -->

    <div class="col-sm-12 col-md-4 col-lg-4 grid-margin">
      <div class="card">
        <div class="card-body">
          <h4 class="mb-0">Billing Period</h4>
          <hr />
          <table class="table">
            <tr>
              <th>Name</th>
              <td
                class="text-end"
                v-if="biling_information1.message == 'No data found'"
              ></td>
              <td class="text-end" v-else>
                {{ biling_information.billing.package.package_name }}
              </td>
            </tr>
            <tr>
              <th>Users</th>
              <td
                class="text-end"
                v-if="biling_information1.message == 'No data found'"
              ></td>
              <td class="text-end" v-else>{{ total_adition() }}</td>
            </tr>
            <!-- <tr>
              <th>Partners</th>
              <td class="text-end">2</td>
            </tr> -->
            <tr>
              <td></td>
            </tr>
            <!-- <tr>
              <td colspan="2">Number of days left: 2005</td>
            </tr> -->
            <tr>
              <td>Expiry date</td>
              <td v-if="biling_information1.message == 'No data found'"></td>
              <td v-else>
                {{ biling_information.billing.billing_period_end }}
              </td>
            </tr>
          </table>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <button
              class="btn btn-success mt-2 px-5 py-2"
              data-bs-toggle="modal"
              data-bs-target="#upgradeModal"
            >
              Upgrade Now
            </button>
            <!-- Modal -->
            <div
              class="modal fade"
              id="upgradeModal"
              tabindex="-1"
              aria-labelledby="upgradeModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="upgradeModalLabel">
                      Upgrade Package Confirmation
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div style="font-weight: 500">
                      Are you sure you want to upgrade your Jurnal package?
                    </div>
                    <div>
                      Learn more about your subscription and proceed by contacting our
                      support team.
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary px-5 py-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" class="btn btn-primary px-5 py-2">
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-4 col-lg-4 grid-margin">
      <div class="card h-100">
        <div class="card-body">
          <h4 class="mb-0">Billing Information</h4>
          <hr />
          <table class="table">
            <tr>
              <th>Billing Cycle</th>
              <td class="text-end" v-if="biling_information1.message == 'No data found'">
                Month
              </td>
              <td class="text-end" v-else>
                {{ biling_information.duration_in_month || "-" }} Month
              </td>
            </tr>
            <tr>
              <th>Next Billing Date</th>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-4 col-lg-4 grid-margin">
      <div class="card h-100">
        <div class="card-body d-flex align-items-center justify-content-center">
          <router-link
            :to="{ name: 'BillingPay' }"
            class="btn btn-outline-success btn-icon-text px-5 py-2"
          >
            Add Quota
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <!-- <input
                v-model="search"
                placeholder="Search"
                style="
                  float: right;
                  background-color: transparent;
                  border-color: aliceblue;
                  color: aliceblue;
                "
                class="col-md-2"
              /> -->

            <table class="table">
              <thead>
                <tr>
                  <td>Pay Date</td>
                  <td>Billing Period</td>
                  <td>Total Amount</td>
                  <td>Type</td>
                  <td>Status</td>

                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in biling" :key="index">
                  <td>{{ formatDate(data.billing_transaction.created_at) }}</td>
                  <td>
                    {{ formatDate(data.billing_period_start) }} -
                    {{ formatDate(data.billing_period_end) }}
                  </td>
                  <td>
                    {{ formatPrice(data.billing_transaction.amount) }}
                  </td>
                  <td>
                    <span style="font-weight: bold">{{
                      data.billing_transaction.type
                    }}</span>
                  </td>
                  <td>
                    <span style="font-weight: bold">{{
                      data.billing_transaction.status
                    }}</span>
                  </td>

                  <td>
                    <a
                      v-if="
                        data.billing_transaction.status == 'pending' &&
                        data.billing_transaction.type == 'digital'
                      "
                      :href="data.billing_transaction.checkout_link"
                      class="btn btn-info"
                      download
                      target="_blank"
                      ><i class="mdi mdi-cash m-0"> </i>Pay</a
                    >
                    <a
                      v-if="
                        data.billing_transaction.status == 'pending' &&
                        data.billing_transaction.type == 'manual' &&
                        data.billing_transaction.amount_payment_proof == null
                      "
                      @click="paymanual"
                      class="btn btn-info"
                      download
                      target="_blank"
                      ><i class="mdi mdi-cash m-0"> </i> Pay Manual</a
                    >
                    &nbsp;
                    <button class="btn btn-primary" @click="openmodalstatus(data.id)">
                      <i class="mdi mdi-eye m-0"> </i> Detail
                    </button>
                    &nbsp;
                    <a
                      :href="data.billing_transaction.invoice"
                      class="btn btn-success"
                      download
                      target="_blank"
                      ><i class="mdi mdi-download m-0"> </i>Download Invoice</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <div v-if="transaction.length > 0">
            <PaginationComponents
              :totalItems="transaction.length"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @page-changed="onPageChanged"
            />
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="isModalstatus" class="custom-modal">
      <div class="custom-modal-content" style="background-color: #eee; color: black">
        <div class="custom-modal-header">
          <img width="100" src="../assets/Lightech.png" alt="" />
          <!-- <h1 style="color:black"> Detail</h1> -->
          <button type="button" class="close" @click="closeModalStatus">&times;</button>
        </div>
        <div class="custom-modal-body">
          <div class="container">
            <div class="container">
              <h3 style="float: left">Information</h3>
              <br />
              <br />
              <br />
              <div class="row">
                <div class="col">
                  <span>Status :</span><br /><br />
                  <span>Reason For Reject :</span>
                </div>

                <div class="col">
                  <b>
                    <td>
                      <span
                        class="badge badge-warning"
                        v-if="modal_status.billing_transaction.status == 'pending'"
                        >Pending</span
                      >
                      <span
                        class="badge badge-success"
                        v-if="modal_status.billing_transaction.status == 'paid'"
                        >Paid</span
                      >
                      <span
                        class="badge badge-danger"
                        v-if="modal_status.billing_transaction.status == 'expired'"
                        >Expired</span
                      >

                      <span
                        class="badge badge-success"
                        v-if="modal_status.billing_transaction.status == 'success'"
                        >Success</span
                      >
                      <span
                        class="badge badge-danger"
                        v-if="modal_status.billing_transaction.status == 'failed'"
                        >Failed</span
                      >

                      <!-- {{data.billing_transaction.status}} -->
                    </td></b
                  >
                  <br /><br />
                  <b
                    ><span>{{
                      modal_status.billing_transaction.reason_for_reject
                    }}</span></b
                  ><br />
                </div>
              </div>
              <br />
              <hr />
              <br />

              <h3 style="float: left">Package</h3>
              <br />
              <br />
              <div class="row">
                <div class="col">
                  <span>Package :</span>
                  <br />
                  <span>Price Per Mounth :</span>
                </div>
                <div class="col">
                  <b
                    ><span>{{ modal_status.package.package_name }}</span></b
                  ><br />
                  <b
                    ><span>
                      {{
                        formatPrice(modal_status.package.price_per_person_per_month)
                      }}</span
                    ></b
                  ><br />
                </div>
              </div>
              <!-- <div class="row"> -->
              <!-- <div class="col">
                  <h4>Payment Proof</h4>
                </div> -->
              <!-- <a :href="bilingadmindetial.billing_transaction.payment_proof" download target="_blank" style="color:black;font-weight:bold;float:right;">
<i class="mdi mdi-evernote" ></i> Lihat Payment Proof
                    </a> -->
              <!-- <img
                  :src="modal_status.billing_transaction.payment_proof"
                  alt=""
                  width="100%"
                /> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="page-header">
      <h3 class="page-title">Billing</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="input-group">
              <input
                v-model.trim="searchadmin"
                @keyup="getBilingAdminsearch"
                placeholder="Search"
                style="
                  float: right;
                  background-color: transparent;
                  border-color: aliceblue;
                  color: aliceblue;
                "
                class="col-md-2"
              />

              <div class="input-group-append">
                <span class="input-group-text" style="border-color: aliceblue"
                  ><i class="mdi mdi-magnify"></i
                ></span>
              </div>
            </div>
            <br />

            <table class="table">
              <thead>
                <tr>
                  <td>NO</td>
                  <td>Company Name</td>
                  <td>Packages</td>
                  <td>Description</td>
                  <td>Pay Date</td>
                  <td>Billing Period</td>
                  <td>Duration</td>
                  <td>Total Amount</td>
                  <td>Status</td>
                  <td>Type</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginationAdmin" :key="data.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    <span v-if="data.company != null">{{ data.company.name }}</span>
                  </td>
                  <td>{{ data.package.package_name }}</td>
                  <td>{{ data.desc }}</td>
                  <td>{{ formatDate(data.created_at) }}</td>
                  <td>
                    {{ formatDate(data.billing_period_start) }} -
                    {{ formatDate(data.billing_period_end) }}
                  </td>
                  <td>{{ data.duration }} Month</td>
                  <td>
                    {{ formatPrice(data.billing_transaction.amount) }}
                  </td>
                  <td>
                    <span
                      class="badge badge-warning"
                      v-if="data.billing_transaction.status == 'pending'"
                      >Pending</span
                    >
                    <span
                      class="badge badge-success"
                      v-if="data.billing_transaction.status == 'paid'"
                      >Paid</span
                    >
                    <span
                      class="badge badge-danger"
                      v-if="data.billing_transaction.status == 'expired'"
                      >Expired</span
                    >

                    <span
                      class="badge badge-success"
                      v-if="data.billing_transaction.status == 'success'"
                      >Success</span
                    >
                    <span
                      class="badge badge-danger"
                      v-if="data.billing_transaction.status == 'failed'"
                      >Failed</span
                    >

                    <!-- {{data.billing_transaction.status}} -->
                  </td>
                  <td>
                    <span v-if="data.billing_transaction.type == 'digital'">Online</span>
                    <span v-if="data.billing_transaction.type == 'manual'">Manual</span>
                  </td>

                  <td v-if="data.billing_transaction.type == 'digital'"></td>
                  <td v-if="data.billing_transaction.type == 'manual'">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul class="dropdown-menu" style="background-color: #eee">
                        <li>
                          <a
                            href="#"
                            class="dropdown-item"
                            @click="opendetail(data.id)"
                            style="color: black"
                            >Detail
                          </a>
                        </li>
                        <li>
                          <a
                            v-if="
                              data.billing_transaction.status != 'paid' &&
                              data.billing_transaction.status != 'failed'
                            "
                            href="#"
                            class="dropdown-item"
                            @click="approv(data.id)"
                            style="color: black"
                            >Approve
                          </a>
                        </li>
                        <li>
                          <a
                            v-if="
                              data.billing_transaction.status != 'paid' &&
                              data.billing_transaction.status != 'failed'
                            "
                            href="#"
                            class="dropdown-item"
                            @click="openreject(data.id)"
                            style="color: black"
                            >Reject
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr v-if="paginationAdmin.length === 0">
                  <td colspan="11" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="bilingadmin.length > 0">
            <PaginationComponents
              :totalItems="bilingadmin.length"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @page-changed="onPageChanged"
            />
          </div>
          <div v-if="isModalOpen" class="custom-modal">
            <div
              class="custom-modal-content"
              style="background-color: #eee; color: black"
            >
              <div class="custom-modal-header">
                <img width="100" src="../assets/Lightech.png" alt="" />
                <!-- <h1 style="color:black"> Detail</h1> -->
                <button type="button" class="close" @click="closeModal">&times;</button>
              </div>
              <div class="custom-modal-body">
                <div class="container">
                  <h3 style="float: right">Company</h3>
                  <br />
                  <br />
                  <div class="row">
                    <div class="col">
                      <span>Name Company :</span>
                    </div>
                    <div class="col">
                      <b
                        ><span v-if="bilingadmindetial.company != null">{{
                          bilingadmindetial.company.name
                        }}</span></b
                      ><br />
                    </div>
                  </div>
                  <br />
                  <hr />
                  <h3 style="float: right">Package</h3>
                  <br />
                  <br />
                  <div class="row">
                    <div class="col">
                      <span>Package :</span>
                      <br />
                      <span>Price :</span>
                    </div>
                    <div class="col">
                      <b
                        ><span>{{ bilingadmindetial.package.package_name }}</span></b
                      ><br />
                      <b
                        ><span>
                          {{
                            formatPrice(
                              bilingadmindetial.package.price_per_person_per_month
                            )
                          }}</span
                        ></b
                      ><br />
                    </div>
                  </div>
                  <br />
                  <hr />
                  <div class="row">
                    <div class="col">
                      <h4>Biling Transaction</h4>
                    </div>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Invoice Number</th>
                          <th>Bank</th>
                          <th>Bank Account Name</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="color: black; font-weight: bold">
                          <td>
                            {{ bilingadmindetial.billing_transaction.invoice_number }}
                          </td>
                          <td>
                            {{ bilingadmindetial.billing_transaction.bank }}
                          </td>
                          <td>
                            {{ bilingadmindetial.billing_transaction.bank_account_name }}
                          </td>

                          <td>
                            {{
                              formatPrice(bilingadmindetial.billing_transaction.amount)
                            }}
                          </td>
                        </tr>
                        <tr style="color: black; font-weight: bold">
                          <td class="text-end" colspan="3">Discount</td>
                          <td>
                            {{
                              formatPrice(
                                bilingadmindetial.billing_transaction.disc_amount
                              )
                            }}
                          </td>
                        </tr>
                        <tr style="color: black; font-weight: bold">
                          <td class="text-end" colspan="3">Remaining Package Disc</td>
                          <td>
                            {{
                              formatPrice(
                                bilingadmindetial.billing_transaction
                                  .remaining_package_disc
                              )
                            }}
                          </td>
                        </tr>

                        <tr style="color: black; font-weight: bold">
                          <td class="text-end" colspan="3">Total Amount</td>
                          <td>
                            {{ formatPrice(total_amount()) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col">
                      <h4>Payment Proof</h4>
                    </div>
                    <!-- <a :href="bilingadmindetial.billing_transaction.payment_proof" download target="_blank" style="color:black;font-weight:bold;float:right;">
<i class="mdi mdi-evernote" ></i> Lihat Payment Proof
                    </a> -->
                    <img
                      :src="bilingadmindetial.billing_transaction.payment_proof"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div>
              </div>
              <div class="custom-modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal">
                  Close
                </button>
              </div>
            </div>
          </div>
          <div v-if="isModalOpenReject" class="custom-modal">
            <div
              class="custom-modal-content"
              style="background-color: #eee; color: black"
            >
              <div class="custom-modal-header">
                <img width="100" src="../assets/Lightech.png" alt="" />
                <!-- <h1 style="color:black"> Detail</h1> -->
                <button type="button" class="close" @click="closeModalReject">
                  &times;
                </button>
              </div>
              <div class="custom-modal-body">
                <div class="container">
                  <!-- <h1>{{ billing_id }}</h1> -->
                  <span>Reason For Reject :</span>
                  <textarea
                    name=""
                    class="form-control"
                    id=""
                    cols="50"
                    v-model="reason_for_reject"
                    rows="50"
                    style="
                      background-color: #eee;
                      color: black;
                      height: 150px;
                      font-size: 20px;
                    "
                  ></textarea>
                </div>
              </div>
              <div class="custom-modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModalReject">
                  Close
                </button>

                <button type="button" class="btn btn-danger" @click="actionreject()">
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PaginationComponents from 'components/PaginationComponents.vue';
import PaginationComponents from "../components/PaginationComponents.vue";
import moment from "moment";
import axios from "axios";
import config from "../config/config";
import store from "../store";
import Swal from "sweetalert2";

export default {
  name: "BillingView",
  components: {
    PaginationComponents,
  },
  data() {
    return {
      companyOptions: [],
      transaction: [],
      biling_information: [],
      currentPage: 1,
      biling_information1: [],
      aditio: 0,
      limit: 0,
      biling: [],
      bilingadmin: [],
      bilingadmindetial: [],
      searchadmin: "",
      search: "",
      itemsPerPage: 10,
      roleSuperadmin: false,
      isModalOpen: false,
      reason_for_reject: "",
      billing_id: 0,
      isModalOpenReject: false,
      isModalstatus: false,
      modal_status: [],
      fetchDone: false,
      nama_package: "",
      discont: 0,
      amount: 0,
      remaining_package_disc: 0,

      invoice: sessionStorage.getItem("noinvoice"),
      amount_payment_proof: sessionStorage.getItem("amount"),
      payment_date: moment().format("YYYY-MM-DD"),

      billingpay_id: sessionStorage.getItem("billing_id"),
    };
  },
  methods: {
    paymanual() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("billing_id", this.billingpay_id);
      formData.append("type", "manual");
      formData.append("_method", "POST");

      axios.post(`${API_URL}billing/select/payment-method`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // .then((resp) => {
      this.$router.push({ path: "detail" });
      // console.log(resp);
      // })
      // .catch((err) => {
      //   console.log(err.response);
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: err.response.data.message,
      //   });
      // });
    },
    total_adition: function () {
      return parseInt(this.aditio) + parseInt(this.limit);
    },
    getbilingInformation() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}subscription-package`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.biling_information = resp.data.data;
          _.biling_information1 = resp.data;
          _.aditio = resp.data.data.billing.additional_user;
          _.limit = resp.data.data.billing.package.limit_user;
          console.log(_.biling_information);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    total_amount: function () {
      return (
        parseInt(this.amount) -
        parseInt(this.discont) -
        parseInt(this.remaining_package_disc)
      );
    },
    openmodalstatus(id) {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}billing/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.modal_status = resp.data.data;
          this.isModalstatus = true;
          console.log(_.modal_status);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    opendetail(id) {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}admin/billing/detail/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.bilingadmindetial = resp.data.data;
          _.discont = resp.data.data.billing_transaction.disc_amount;
          _.remaining_package_disc =
            resp.data.data.billing_transaction.remaining_package_disc;
          _.amount = resp.data.data.billing_transaction.amount;
          this.isModalOpen = true;
          console.log(_.bilingadmindetial);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    openreject(id) {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}admin/billing/detail/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.billing_id = resp.data.data.billing_transaction.billing_id;
          this.isModalOpenReject = true;
          console.log(_.bilingadmindetial);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    approv(id) {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      // let _ = this;
      axios
        .get(`${API_URL}admin/billing/approve/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          Swal.fire({
            title: "Approve!",
            text: "Berhasil Approve Billing",
            icon: "success",
            buttons: ["NO", "YES"],
          }).then(function (isConfirm) {
            if (isConfirm) {
              location.reload();
            } else {
              //if no clicked => do something else
            }
          });
          // Swal.fire(
          //   {
          //     icon: "success",
          //     title: "Approve!",
          //     text: "Berhasil Approve Billing",
          //     confirmButtonColor: "#0090e7",

          //   }
          // );

          console.log(resp);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
            confirmButtonColor: "#0090e7",
          });
          console.log(err.response);
        });
    },
    actionreject() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("billing_id", this.billing_id);
      formData.append("reason_for_reject", this.reason_for_reject);

      formData.append("_method", "POST");

      // let _ = this;
      axios
        .post(`${API_URL}admin/billing/reject`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          console.log(resp);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Success Reject",
          });
          window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
          console.log(err.response);
        });
    },

    filteredItems() {
      return this.biling.filter((item) => {
        return (
          item.billing_period_end.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    closeModalReject() {
      this.isModalOpenReject = false;
    },
    closeModalStatus() {
      this.isModalstatus = false;
    },
    paginatedRedeem() {
      if (this.fetchDone) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.biling.slice(start, end);
      } else {
        return [];
      }
    },

    formatPrice(value) {
      const intValue = parseInt(value, 10);
      return `Rp. ${intValue.toLocaleString("id-ID")}`;
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    getBiling() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}billing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.biling = resp.data.data;
          console.log(_.biling);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    onPageChanged(page) {
      this.currentPage = page;
    },
    getBilingAdmin() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}admin/billing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.bilingadmin = resp.data.data.data;
          this.fetchDone = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getBilingAdminsearch() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}admin/billing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (this.searchadmin) {
            this.bilingadmin = resp.data.data.data.filter((bilingadmin) =>
              bilingadmin.company.name
                .toLowerCase()
                .includes(this.searchadmin.toLowerCase())
            );
          } else {
            _.bilingadmin = resp.data.data.data;
          }
        });
    },
  },

  async mounted() {
    this.getbilingInformation();
    this.getBiling();
    this.getBilingAdminsearch();
    this.filteredItems();
    this.getBilingAdmin();

    const user = JSON.parse(JSON.parse(localStorage.getItem("user"))).data.role;
    this.roleSuperadmin = user === "superadmin";
  },
  computed: {
    paginationAdmin() {
      if (this.fetchDone) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.bilingadmin.slice(start, end);
      } else {
        return [];
      }
    },
  },
};
</script>
<style>
.dropdown-menu > li > a:hover {
  background-image: none;
  background-color: #eee;
}
</style>
