<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">YourSubcription</h3>
    </div>
    <!-- <div class="col-12 grid-margin">
        <router-link :to="{ name: 'CreateReward' }" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2">
          <i class="mdi mdi-plus btn-icon-prepend"></i> Insert new Data
        </router-link>
      </div> -->

    <div class="col-md-8 grid-margin">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">Activate package</div>
                <div class="col-md-9">
                  <h5>Enterprise</h5>
                  <table class="table">
                    <tr>
                      <td class="pb-1">Free:</td>
                      <td class="pb-1">Additional:</td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <ul class="mb-0">
                          <li>Partner 2 partner</li>
                          <li>User: 5 of 5 users</li>
                        </ul>
                      </td>
                      <td class="py-0">
                        <ul class="mb-0">
                          <li>User: 0 user</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5>New package</h5>
                <!-- <a href="#">Compare subscription</a> -->
              </div>
              <div class="table-responsive">
                <table class="table table-bordered mt-2">
                  <thead>
                    <tr>
                      <th>Packages</th>
                      <th>Price per unit</th>
                      <th>Qty</th>
                      <th>Duration</th>
                      <th>Total price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          class="form-select-lg col-md-10"
                          v-model="selected"
                          @change="onChange($event)"
                        >
                          <option value="">Select Package</option>
                          <option
                            v-for="item in packages"
                            :key="item.id"
                            v-bind:value="{
                              id: item.id,
                              text: item.price_per_person_per_month,
                            }"
                          >
                            {{ item.package_name }}
                          </option>
                        </select>
                      </td>
                      <td>
                        Rp. {{ formatPrice(selected.text || 0) }}
                        <!-- {{selected.id}}
                        {{selected.text}} -->

                        <input
                          type="hidden"
                          name="harga_bulan"
                          :value="selected.text"
                          :harga_bulan="harga_bulan"
                          v-on:input="update_number1"
                        />
                        <input
                          type="hidden"
                          name="package_id"
                          :value="selected.id"
                          :package_id="package_id"
                          v-on:input="update_number6"
                        />

                        <!-- <input
                          type="text"
                         
                          :v-model="package_id"

                        /> -->
                        <!-- <p>{{ harga_bulan }}</p>
                        <p>{{ model.package_id }}</p> -->
                      </td>

                      <td>1</td>
                      <td>
                        <select
                          class="form-select"
                          name="duration"
                          :value="model.duration"
                          v-on:input="update_number2"
                        >
                          <option value="1">1 Month</option>
                          <option value="3">3 Month</option>
                          <option value="6">6 Month</option>
                          <option value="9">9 Month</option>
                          <option value="12" selected>12 Month</option>
                        </select>
                      </td>
                      <td class="text-end">Rp. {{ formatPrice(sub_total() || 0) }}</td>
                    </tr>
                    <tr>
                      <td>Additional user</td>
                      <td class="">Rp. {{ formatPrice(aditiuser || 0) }}</td>
                      <td>
                        <input
                          type="number"
                          class="form-control qty"
                          v-model="model.additional_user"
                          v-on:input="update_number3"
                        />
                        <input
                          type="hidden"
                          class="form-control qty"
                          v-model="aditiuser"
                          v-on:input="update_number4"
                        />
                      </td>
                      <td>
                        <select
                          class="form-select"
                          name="duration"
                          :value="model.additional_user_duration"
                          v-on:input="update_number4"
                        >
                          <option value="1">1 Month</option>
                          <option value="3">3 Month</option>
                          <option value="6">6 Month</option>
                          <option value="9">9 Month</option>
                          <option value="12" selected>12 Month</option>
                        </select>
                      </td>
                      <td class="text-end">Rp {{ formatPrice(total_adition() || 0) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3"></td>
                      <td>Total</td>

                      <td class="text-end">Rp. {{ formatPrice(result() || 0) }}</td>
                      <!-- {{formatPrice(totals())}} -->
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5>New package summary</h5>
          <p>Package Enterprise and 0 additional user for 12 month</p>
          <table class="table">
            <tr>
              <td>New Bill</td>
              <td class="text-end">Rp. {{ formatPrice(sub_total() || 0) }}</td>
            </tr>
            <!-- <tr>
              <td>PPN 11%</td>
              <td class="text-end">Rp. {{ formatPrice(totals()) }}</td>
            </tr> -->
            <tr style="border-bottom: 1px solid #2c2e33">
              <td>Credit</td>
              <td class="text-end">(Rp. 0)</td>
            </tr>
            <tr>
              <td>Total</td>
              <td class="text-end">Rp. {{ formatPrice(result() || 0) }}</td>
            </tr>
          </table>
          <div class="form-check">
            <label for="" style="float: right; color: #eee">Discount Code</label>

            <input
              type="text"
              class="form-control"
              style="background-color: #eee; color: black; font-size: 30px"
              v-model="model.disc_code"
            />
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end py-4">
          <a href="#" @click="back" class="btn btn-outline-warning me-3 px-4 py-2">
            Back
          </a>
          <a href="#" class="btn btn-warning px-4 py-2" @click="pay()"> Pay now </a>

          <!-- Manual Modal -->
          <div v-if="isModalOpen" class="custom-modal">
            <div
              class="custom-modal-content"
              style="background-color: #eee; color: black"
            >
              <div class="custom-modal-header">
                <img width="100" src="../assets/Lightech.png" alt="" />
                <!-- <p style="color:black"> PT Lightech Business Automation</p> -->
                <button type="button" class="close" @click="closeModal">&times;</button>
              </div>
              <div class="custom-modal-body">
                <div class="table-responsive">
                  <span style="float: right; font-size: 37"
                    >Invoice Number : <b>{{ invoice }}</b></span
                  >
                  <br />
                  <br />
                  <table class="table table-bordered">
                    <thead style="color: black">
                      <tr>
                        <th>No</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Duration</th>
                        <th>Total (Rp)</th>
                      </tr>
                    </thead>
                    <tbody style="color: black">
                      <tr>
                        <td>1</td>
                        <td>Payment for Quota</td>
                        <td>1</td>
                        <td>{{ duration }} Month</td>
                        <td>Rp. {{ formatPrice(sub_total()) }}</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Additional user</td>
                        <td>{{ additional_user }}</td>
                        <td>{{ additional_user_duration }} Month</td>
                        <td>Rp. {{ formatPrice(total_adition()) }}</td>
                      </tr>

                      <!-- <tr>
                        <td class="text-end" colspan="3">Subtotal</td>
                        <td>Rp. {{result()}}</td>
                      </tr> -->
                      <!-- <tr>
                        <td class="text-end" colspan="3">PPN</td>
                        <td>Rp. {{ formatPrice(totals()) }}</td>
                      </tr> -->
                      <!-- <tr>
                        <td class="text-end" colspan="3">Deposit</td>
                        <td>Rp. {{formatPrice(result())}}</td>
                      </tr> -->

                      <!-- <tr>
                        <td class="text-end" colspan="3">Additional user</td>
                        <td>
                          Rp. {{ formatPrice(total_adition()) }} -
                          {{ additional_user }} Qty -
                          {{ additional_user_duration }} Month
                        </td>
                      </tr> -->
                      <tr>
                        <td></td>
                        <td class="text-end" colspan="3">Discount</td>
                        <td>Rp. {{ formatPrice(disc_amount) }}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="text-end" colspan="3">Remaining Package Disc</td>
                        <td>Rp. {{ formatPrice(remaining_package_disc) }}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="text-end" colspan="3">Total</td>
                        <td>Rp. {{ formatPrice(total_semua()) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="text-danger mt-3">Warning:</p>
                <p style="color: black">
                  1. Minimal transaksi Rp 10.000 setiap bulannya sama dengan BCA Virtual
                  Account.
                </p>
                <p style="color: black">
                  2. Nomor Virtual Account BCA akan menunjukkan total tagihan yang aktif.
                </p>
                <p style="color: black">
                  3. Pembayaran dapat dilakukan melalui ATM BCA, KlikBCA Individu, KlikBCA
                  Bisnis, BCA Mobile, dan Teller.
                </p>
                <p style="color: black">
                  4. Pembayaran juga dapat dilakukan melalui Switching.
                </p>
              </div>
              <div class="custom-modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal">
                  Close
                </button>
                <button type="button" class="btn btn-primary" @click="payxendit">
                  Online Payment
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="openConfirmationModal"
                >
                  Transfer Manual
                </button>
              </div>
            </div>
          </div>

          <!-- Confirmation Modal -->
          <div v-if="isConfirmationModalOpen" class="custom-modal">
            <div class="custom-modal-content custom-modal-centered">
              <div class="custom-modal-header">
                <h4>Confirm Transfer</h4>
                <button type="button" class="close" @click="closeConfirmationModal">
                  &times;
                </button>
              </div>
              <div class="custom-modal-body text-center">
                <p>
                  Payment for this bill can be made via manual transfer to the following
                  account. If you have made the payment, please confirm the payment proof.
                </p>
                <p class="mb-0">BCA PT Lightech Business Automation</p>
                <p>Account Number: 5892 000 000</p>
                <!-- <p style="float: left">Bukti Transfer</p> -->
                <!-- <input
                  type="file"
                  :v-model="payment_proof"
                  class="form-control"
                  @change="handleFileUpload($event)"
                  required
                /> -->
              </div>
              <div class="custom-modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="closeConfirmationModal"
                >
                  Postpone Confirmation
                </button>
                <button type="button" class="btn btn-primary" @click="paymanual">
                  Confirm Now
                </button>
              </div>
            </div>
          </div>

          <div v-if="isOnlinePaymentModalOpen" class="custom-modal">
            <div class="custom-modal-content custom-modal-centered">
              <div class="custom-modal-header">
                <h4>Confirm Transfer</h4>
                <button type="button" class="close" @click="closeOnlinePayment">
                  &times;
                </button>
              </div>
              <div class="custom-modal-body text-center">
                <h3 class="mb-0">Payment for this method not existed yet.</h3>
              </div>
              <div class="custom-modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="closeOnlinePayment"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import config from "../config/config";
import store from "../store";
import Swal from "sweetalert2";

export default {
  name: "PayView",
  data() {
    return {
      price_per_person_per_month: "",
      selected: "",
      companyOptions: [],
      transaction: [],
      harga_bulan: "",
      model: {
        package_id: 0,
        duration: 1,
        additional_user: 0,
        additional_user_duration: 1,
        disc_code: "",
      },
      package_id: null,
      aditiuser: "",
      disc_amount: 0,
      total: 0,
      packages: [],
      duration: "",
      additional_user: "",
      additional_user_duration: "",
      detail_transaksi: [],
      invoice: "",
      remaining_package_disc: 0,
      billing_id: 0,
      payment_proof: "",
      mountedDone: false,
      currentPage: 1,

      itemsPerPage: 10,
      isModalOpen: false,
      isConfirmationModalOpen: false,
      isOnlinePaymentModalOpen: false,
    };
  },

  mounted() {
    let _ = this;
    _.getPackages();
  },
  methods: {
    onChange() {
      this.harga_bulan = this.selected.text;
      this.model.package_id = this.selected.id;
      this.aditiuser = this.selected.text;
    },
    handleFileUpload(event) {
      this.payment_proof = event.target.files[0];
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    update_number1: function (event) {
      this.harga_bulan = event.target.value;
    },
    update_number6: function (event) {
      this.package_id = event.target.value;
    },

    update_number2: function (event) {
      this.model.duration = event.target.value;
    },
    update_number3: function (event) {
      this.model.additional_user = event.target.value;
    },
    update_number4: function (event) {
      this.model.additional_user_duration = event.target.value;
    },
    total_adition: function () {
      return (
        parseInt(this.model.additional_user) *
        parseInt(this.aditiuser) *
        parseInt(this.model.additional_user_duration)
      );
    },
    result: function () {
      return this.sub_total() + this.total_adition();
    },

    total_semua: function () {
      return (
        this.result() - parseInt(this.disc_amount) - parseInt(this.remaining_package_disc)
      );
    },
    sub_total: function () {
      return parseInt(this.harga_bulan) * parseInt(this.model.duration);

      //   return this.harga_bulan * this.bulan;
    },
    sub_total1: function () {
      return parseInt(this.harga_bulan) * parseInt(this.model.additional_user);

      //   return this.harga_bulan * this.bulan;
    },
    totals: function () {
      return (this.sub_total() + this.sub_total1()) * 0.11;
    },

    getPackages() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}package`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.packages = resp.data.data;
          // _.harga_bulan = resp.data.data[0].price_per_person_per_month;
          // _.aditiuser = resp.data.data[0].price_per_person_per_month;
          // _.model.package_id = resp.data.data[0].id;
          console.log(resp);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    pay() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("package_id", this.model.package_id);
      formData.append("duration", this.model.duration);
      formData.append("additional_user", this.model.additional_user);
      formData.append("additional_user_duration", this.model.additional_user_duration);
      formData.append("disc_code", this.model.disc_code);
      formData.append("_method", "POST");

      let _ = this;
      axios
        .post(`${API_URL}billing/store`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          console.log(resp);
          _.detail_transaksi = resp.data.data;
          _.invoice = resp.data.data.billing_transaction.invoice_number;
          _.billing_id = resp.data.data.billing_transaction.billing_id;
          _.disc_amount = resp.data.data.billing_transaction.disc_amount;
          _.remaining_package_disc =
            resp.data.data.billing_transaction.remaining_package_disc;
          _.duration = resp.data.data.billing.duration;

          _.additional_user = resp.data.data.billing.additional_user;
          _.additional_user_duration = resp.data.data.billing.additional_user_duration;

          sessionStorage.setItem(
            "noinvoice",
            resp.data.data.billing_transaction.invoice_number
          );
          sessionStorage.setItem("amount", this.total_semua());
          sessionStorage.setItem("remaining_package_disc", this.remaining_package_disc);

          sessionStorage.setItem(
            "billing_id",
            resp.data.data.billing_transaction.billing_id
          );

          //   _.$store.dispatch('noinvoice',  );
          //   _.$store.dispatch('amount',  resp.data.data.billing_transaction.amount);

          _.isModalOpen = true;
          console.log(_.detail_transaksi);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.errors,
          });
          console.log(err.response);
        });
    },

    payxendit() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("billing_id", this.billing_id);
      formData.append("type", "digital");
      formData.append("_method", "POST");

      axios
        .post(`${API_URL}billing/select/payment-method`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          window.open(resp.data.data.payment_url, "_blank");
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    paymanual() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("billing_id", this.billing_id);
      formData.append("type", "manual");
      formData.append("_method", "POST");

      axios.post(`${API_URL}billing/select/payment-method`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // .then((resp) => {
      this.$router.push({ name: "PayDetail" });
      // this.$router.push({ name: "PayDetail" });

      // console.log(resp);
      // })
      // .catch((err) => {
      //   console.log(err.response);
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: err.response.data.message,
      //   });
      // });
    },
    // async fetchData() {
    //   await getPackages().then((response) => {
    //     this.packages = response.data.data;
    //   }).catch(error => {
    //     console.error('Error fetching rewardApprovals:', error);
    //   })

    // },
    back() {
      // this.$router.push({ name: "AddQuota" });
      this.$router.go(-1);
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openConfirmationModal() {
      this.closeModal();
      this.isConfirmationModalOpen = true;
    },
    closeConfirmationModal() {
      this.isConfirmationModalOpen = false;
    },
    openOnlinePayment() {
      this.closeModal();
      this.isOnlinePaymentModalOpen = true;
    },
    closeOnlinePayment() {
      this.isOnlinePaymentModalOpen = false;
    },
    confirmTransfer() {
      // Add your transfer logic here
      this.$router.push({ name: "PayDetail", params: { id: 1 } });
      this.closeConfirmationModal();
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.form-select {
  padding: 10px;
  width: 120px;
}
.qty {
  width: 60px;
}
</style>
