import axios from "axios";
import config from "../config/config";
import store from "../store"

const API_URL = config.API_URL; 

export const Store = async (data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}auth/register`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};
