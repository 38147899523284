<template>
  <div class="container-fluid container-login">
    <div class="content-wrapper">
      <div class="row justify-content-center">
        <div class="col-md-7">
          <div class="card login">
            <div class="card-header text-center">
              <img
                width="150"
                height=""
                class="mx-auto"
                src="../assets/images/logo-big.svg"
                alt=""
              />
            </div>
            <div class="card-body px-5 pt-5">
              <h5 class="card-title mb-2 text-center">User Registrasi</h5>
              <br />
              <form
                class="form-login"
                @submit.prevent="submitForm"
                enctype="multipart/form-data"
              >
                <div class="form-group mb-4">
                  <label for="password" class="form-label">Company Name *</label>
                  <input
                    class="form-control"
                    type="text"
                    id="password"
                    required
                    v-model="ownerStore.companyName"
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <label for="password" class="form-label">Owner Name *</label>
                      <input
                        class="form-control"
                        type="text"
                        id="password"
                        required
                        v-model="ownerStore.ownerName"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <label for="password" class="form-label">Phone Number *</label>
                      <input
                        class="form-control"
                        type="text"
                        id="password"
                        v-model="ownerStore.phoneNumber"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <label for="password" class="form-label">Number Of Players *</label>
                      <input
                        class="form-control"
                        type="text"
                        v-model="ownerStore.numberOfPlayers"
                        id="password"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <label for="password" class="form-label">Subscription*</label>
                      <select
                        class="form-control"
                        id="subscription"
                        v-model="ownerStore.subscription"
                      >
                        <option value="1 Month">1 Month</option>
                        <option value="2 Month">2 Month</option>
                        <option value="3 Month">3 Month</option>
                        <option value="4 Month">4 Month</option>
                        <option value="5 Month">5 Month</option>
                        <option value="6 Month">6 Month</option>
                        <option value="7 Month">7 Month</option>
                        <option value="8 Month">8 Month</option>
                        <option value="9 Month">9 Month</option>
                        <option value="10 Month">10 Month</option>
                        <option value="11 Month">11 Month</option>
                        <option value="12 Month">12 Month</option>
                      </select>
                    </div>
                  </div>
                </div>

                <input
                  type="hidden"
                  value="unpaid"
                  v-model="ownerStore.paymentStatus"
                  name=""
                  id=""
                />
                <div class="form-group">
                  <label for="password" class="form-label">Company Logo*</label>
                  <input
                    type="file"
                    class="form-control dropify"
                    @change="handleFileChange"
                    ref="fileInput"
                  />
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="email" class="form-label">Email *</label>
                      <input
                        class="form-control"
                        type="text"
                        id="email"
                        v-model="ownerStore.emailAddress"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-4">
                      <label for="password" class="form-label"> Set Password *</label>
                      <input
                        class="form-control"
                        type="password"
                        id="password"
                        v-model="ownerStore.password"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <label for="password" class="form-label"> Confirm Password *</label>
                    <input
                      class="form-control"
                      type="password"
                      id="password"
                      v-model="confirmPassword"
                      @input="validateForm"
                      required
                    />
                  </div>
                </div>
                <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
                <div class="text-center mb-3">
                  <button
                    type="submit"
                    class="btn btn-login px-3 form-control"
                    :disabled="loading"
                  >
                    <div
                      class="spinner-border text-light me-2"
                      role="status"
                      v-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Register
                  </button>
                </div>
              </form>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isModalOpen" class="custom-modal">
      <div class="custom-modal-content custom-modal-centered costom-modal-sm">
        <div class="custom-modal-body text-centx er">
          <h3 class="mb-0">Enter your email address</h3>

          <input
            type="email"
            v-model="email"
            class="form-control"
            name=""
            id=""
            required
          />
        </div>
        <div class="custom-modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>

          <button type="button" class="btn btn-primary" @click="proses_forgot_password">
            Forgot Password
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "dropify";
// import { Store } from "@services/regisServices";
import { Store } from "@/services/regisServices";

import Swal from "sweetalert2";

export default {
  data: () => ({
    confirmPassword: "",
    errorMessage: "",
    ownerStore: {
      companyName: "",
      ownerName: "",
      emailAddress: "",
      phoneNumber: "",
      password: "",
      numberOfPlayers: "",
      subscription: "",
      paymentStatus: "",
      companyLogo: null,
      domain: "",
    },
    loading: false,
  }),
  mounted() {
    $(".dropify").dropify({
      messages: {
        default: "Drag and drop a file here or click",
        replace: "Drag and drop or click to replace",
        remove: "Remove",
        error: "Ooops, something wrong happened.",
      },
      error: {
        fileSize: "The file size is too big ({{ value }} max).",
        minWidth: "The image width is too small ({{ value }}}px min).",
        maxWidth: "The image width is too big ({{ value }}}px max).",
        minHeight: "The image height is too small ({{ value }}}px min).",
        maxHeight: "The image height is too big ({{ value }}}px max).",
        imageFormat: "The image format is not allowed ({{ value }} only).",
      },
      tpl: {
        wrap: '<div class="dropify-wrapper"></div>',
        loader: '<div class="dropify-loader"></div>',
        message:
          '<div class="dropify-message"><span class="file-icon" /> <p>{{ default }}</p></div>',
        preview:
          '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"></div></div>',
        filename: '<p class="dropify-filename"><span class="file-icon" /> {{ name }}</p>',
        file: '<div class="dropify-file"></div>',
        clearButton: '<button type="button" class="dropify-clear">Remove</button>',
        errorLine: '<p class="dropify-error">{{ error }}</p>',
        errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>',
      },
    });

    this.ownerStore.subscription = "1 Month";
    this.ownerStore.paymentStatus = "paid";
  },
  methods: {
    validateForm() {
      if (this.ownerStore.password !== this.confirmPassword) {
        this.errorMessage = "Passwords do not match";
        return false;
      }
      this.errorMessage = "";
      return true;
    },
    async submitForm() {
      this.loading = true;
      try {
        await Store(this.ownerStore);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Register successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.push({ name: "Login" });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.errors[0],
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        this.ownerStore.companyLogo = event.target.files[0];
      } else {
        this.ownerStore.companyLogo = null; // Ensure it's null if no files are selected
      }
    },
  },
};
</script>
