<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Add Quota</h3>
    </div>
    <!-- <div class="col-12 grid-margin">
        <router-link :to="{ name: 'CreateReward' }" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2">
          <i class="mdi mdi-plus btn-icon-prepend"></i> Insert new Data
        </router-link>
      </div> -->

    <div class="col-md-12 grid-margin">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- <div class="col-md-3">Activate package</div> -->
                <div class="col-md-12">
                  <div class="card h-300">
                    <div
                      class="card-body d-flex align-items-center justify-content-center"
                    >
                      <router-link
                        :to="{ name: 'Pay' }"
                        class="btn btn-outline-success btn-icon-text px-5 py-2"
                      >
                        Add Quota
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import config from "../config/config";
import store from "../store";
import Swal from "sweetalert2";

export default {
  name: "AddquotaVue",
  data() {
    return {
      price_per_person_per_month: "",
      selected: "",
      companyOptions: [],
      transaction: [],
      harga_bulan: "",
      role: JSON.parse(JSON.parse(localStorage.getItem("user"))).data.role,

      model: {
        package_id: 0,
        duration: 1,
        additional_user: 0,
        additional_user_duration: 1,
        disc_code: "",
      },
      package_id: null,
      aditiuser: "",
      disc_amount: 0,
      total: 0,
      packages: [],
      duration: "",
      additional_user: "",
      additional_user_duration: "",
      detail_transaksi: [],
      invoice: "",
      remaining_package_disc: 0,
      billing_id: 0,
      payment_proof: "",
      mountedDone: false,
      currentPage: 1,

      itemsPerPage: 10,
      isModalOpen: false,
      isConfirmationModalOpen: false,
      isOnlinePaymentModalOpen: false,
    };
  },

  mounted() {
    let _ = this;
    _.getPackages();
    if (this.role == "superadmin") {
      this.$router.push({ name: "Dashboard" });
    }
  },
  methods: {
    onChange() {
      this.harga_bulan = this.selected.text;
      this.model.package_id = this.selected.id;
      this.aditiuser = this.selected.text;
    },
    handleFileUpload(event) {
      this.payment_proof = event.target.files[0];
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    update_number1: function (event) {
      this.harga_bulan = event.target.value;
    },
    update_number6: function (event) {
      this.package_id = event.target.value;
    },

    update_number2: function (event) {
      this.model.duration = event.target.value;
    },
    update_number3: function (event) {
      this.model.additional_user = event.target.value;
    },
    update_number4: function (event) {
      this.model.additional_user_duration = event.target.value;
    },
    total_adition: function () {
      return (
        parseInt(this.model.additional_user) *
        parseInt(this.aditiuser) *
        parseInt(this.model.additional_user_duration)
      );
    },
    result: function () {
      return this.sub_total() + this.total_adition();
    },

    total_semua: function () {
      return (
        this.result() - parseInt(this.disc_amount) - parseInt(this.remaining_package_disc)
      );
    },
    sub_total: function () {
      return parseInt(this.harga_bulan) * parseInt(this.model.duration);

      //   return this.harga_bulan * this.bulan;
    },
    sub_total1: function () {
      return parseInt(this.harga_bulan) * parseInt(this.model.additional_user);

      //   return this.harga_bulan * this.bulan;
    },
    totals: function () {
      return (this.sub_total() + this.sub_total1()) * 0.11;
    },

    getPackages() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}package`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          _.packages = resp.data.data;
          // _.harga_bulan = resp.data.data[0].price_per_person_per_month;
          // _.aditiuser = resp.data.data[0].price_per_person_per_month;
          // _.model.package_id = resp.data.data[0].id;
          console.log(resp);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    pay() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("package_id", this.model.package_id);
      formData.append("duration", this.model.duration);
      formData.append("additional_user", this.model.additional_user);
      formData.append("additional_user_duration", this.model.additional_user_duration);
      formData.append("disc_code", this.model.disc_code);
      formData.append("_method", "POST");

      let _ = this;
      axios
        .post(`${API_URL}billing/store`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          console.log(resp);
          _.detail_transaksi = resp.data.data;
          _.invoice = resp.data.data.billing_transaction.invoice_number;
          _.billing_id = resp.data.data.billing_transaction.billing_id;
          _.disc_amount = resp.data.data.billing_transaction.disc_amount;
          _.remaining_package_disc =
            resp.data.data.billing_transaction.remaining_package_disc;
          _.duration = resp.data.data.billing.duration;

          _.additional_user = resp.data.data.billing.additional_user;
          _.additional_user_duration = resp.data.data.billing.additional_user_duration;

          sessionStorage.setItem(
            "noinvoice",
            resp.data.data.billing_transaction.invoice_number
          );
          sessionStorage.setItem("amount", this.total_semua());
          sessionStorage.setItem("remaining_package_disc", this.remaining_package_disc);

          sessionStorage.setItem(
            "billing_id",
            resp.data.data.billing_transaction.billing_id
          );

          //   _.$store.dispatch('noinvoice',  );
          //   _.$store.dispatch('amount',  resp.data.data.billing_transaction.amount);

          _.isModalOpen = true;
          console.log(_.detail_transaksi);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.errors,
          });
          console.log(err.response);
        });
    },

    payxendit() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("billing_id", this.billing_id);
      formData.append("type", "digital");
      formData.append("_method", "POST");

      axios
        .post(`${API_URL}billing/select/payment-method`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          window.open(resp.data.data.payment_url, "_blank");
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    paymanual() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("billing_id", this.billing_id);
      formData.append("type", "manual");
      formData.append("_method", "POST");

      axios.post(`${API_URL}billing/select/payment-method`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // .then((resp) => {
      this.$router.push({ name: "PayDetail" });
      // this.$router.push({ name: "PayDetail" });

      // console.log(resp);
      // })
      // .catch((err) => {
      //   console.log(err.response);
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: err.response.data.message,
      //   });
      // });
    },
    // async fetchData() {
    //   await getPackages().then((response) => {
    //     this.packages = response.data.data;
    //   }).catch(error => {
    //     console.error('Error fetching rewardApprovals:', error);
    //   })

    // },
    back() {
      this.$router.push({ name: "Login" });
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openConfirmationModal() {
      this.closeModal();
      this.isConfirmationModalOpen = true;
    },
    closeConfirmationModal() {
      this.isConfirmationModalOpen = false;
    },
    openOnlinePayment() {
      this.closeModal();
      this.isOnlinePaymentModalOpen = true;
    },
    closeOnlinePayment() {
      this.isOnlinePaymentModalOpen = false;
    },
    confirmTransfer() {
      // Add your transfer logic here
      this.$router.push({ name: "PayDetail", params: { id: 1 } });
      this.closeConfirmationModal();
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.form-select {
  padding: 10px;
  width: 120px;
}
.qty {
  width: 60px;
}
</style>
